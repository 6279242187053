import './Projects.css';
import Card from '../Card/Card'
import weather from './weather.png'
import weather1 from './weather1.png'
import restro from './restro.png'
import restro1 from './restro1.png'
import csClass from './csClass.png'
import csClass1 from './csClass1.png'
import paint1 from './paint1.png'
import paint2 from './paint2.png'
import studentHive from './studentHive.png'
import studentHive1 from './studentHive1.png'
import colab from './colab.png'
import colab1 from './colab1.png'
import uapaints from './uapaints.png'
import uapaints1 from './uapaints1.png'
// create from megan where you will have read more and like that part, create a timeline too.
function Projects() {


  return (
    <div className="projects">
      <div className="pro_card">
        <Card 
          projectName="StudentHive"
          description="Led CodePath iOS Development class team in creating a student-focused housing app, utilizing Swift for UI and Back4App/Parse for the backend."
          language="Swift Back4App Parse"
          github="true"
          objectives=" During my time as the team leader in the CodePath iOS Development class, I spearheaded the creation of a cutting-edge housing app tailored to students, simplifying their search for temporary accommodation during internships and college years. The project involved implementing a wide range of features, including student listings, flexible lease terms, roommate matching, and short-term rental posts, all of which aimed to provide convenient and affordable housing options. Leveraging the power of Swift, I ensured a seamless user interface and robust functionality. Additionally, for the backend, I seamlessly integrated Back4App and Parse, further enhancing the app's overall performance and efficiency. This experience not only showcased my expertise in iOS app development but also highlighted my ability to lead a team towards a successful and impactful project outcome."
          materials="I used Objective Swift programming language and Github for collaboration with the team members."
          git="https://github.com/Students-Hive/iOS-Final-Project"
          image1={studentHive}
          image2={studentHive1}
        />
        <Card 
          projectName="Disaster Detection on Tweets"
          description="Developed a machine learning model for disaster classification using Tweets, achieving 81% training accuracy and 80% validation accuracy."
          language="Python"
          github="true"
          objectives="In this project, I built a highly effective machine learning model designed to classify real disasters based on the content of Tweets. To begin, I developed a baseline model by carefully analyzing the dataset and conducting thorough data analysis on the training data. I then delved into exploring various models, ultimately implementing both One-Hot Encodings and Embedding models to improve the classification accuracy significantly. As a result of these efforts, the model achieved an impressive 81% accuracy score on the training data and maintained an 80% accuracy score on the validation data for disaster tweet classification. This project demonstrated my proficiency in machine learning techniques and data analysis, showcasing my ability to develop robust solutions for real-world problems."
          materials="I used Python programming language and Colab for collaboration with the team members."
          git="https://colab.research.google.com/drive/1ESNKawPS6Fk-FLrUa_dhCEcNnyZbUv7K?usp=sharing"
          image1={colab}
          image2={colab1}
        />
        <Card 
          projectName="UjjwalAdhikariPaints"
          description="Developed a machine learning model for disaster classification using Tweets, achieving 81% training accuracy and 80% validation accuracy."
          language="Python"
          github="true"
          objectives="Successfully designed, developed, and deployed a full-stack web application for UjjwalAdhikariPaints, a paint sales business. Leveraging React JS and Back4App, I engineered a responsive user interface that included essential features like product listings and product descriptions, while ensuring seamless cross-browser compatibility and mobile responsiveness. This project demonstrated my proficiency in front-end and back-end technologies, as well as my ability to create user-friendly and engaging web applications for businesses."
          materials="I used HTML,CSS, React JS and Back4App programming language and Colab for collaboration with the team members."
          git="https://ujjwaladhikaripaints.com/"
          image1={uapaints}
          image2={uapaints1}
        />
        <Card 
          projectName="Pattern"
          description="Worked as Product Manager in the Product Management class to create a scheduling and habit enforcing app and created its UI."
          language=""
          github="false"
          objectives="Conducted in-depth market research for unmet needs, the main competitors, pain-points, user research and product desires."
          materials="I used Google Slides to present about the product’s agenda, roadmap, problems and goals  to Google’s Product Managers on the last day of class."
          git=""
        />
        <Card 
          projectName="Paint We"
          description="A static front end web application to showcase the paintings using grid properties."
          language="HTML CSS Flask"
          github="true"
          objectives="Coded a static front end web application using HTML/CSS, Flask where users could move through home, paintings, about us page. Used grid properties to layout the paintings."
          materials="I used Google Cloud Shell and Github to coloborate with team members."
          git="Github: https://github.com/Harriee02/CUTT-Project1"
          image1={paint1}
          image2={paint2}
        />
        <Card
          projectName="CS2 Class"
          description="Simple Website to display the projects done by the class students along with project's page."
          language="ReactJS  HTML  CSS  Firebase"
          github="true"
          objectives="Collaborated with the professor to test for the class. Students were able to see what other classmates were working on and it was helpful for the professor to grade the project of students faster. It have the ability where students can create their own profile and the projects they did for the different classes which can be linked there and that profile can act as their portfolio. The website can also list and display different projects along with search options. It has a page for each project which have its own page with title, description, demo, participants, github link etc. One can also give updates on their group projects by posting in their respective project page. Only the member that is working on that project can edit/ update the project. There is also an announcements tab where students can text if they have any questions regarding the class or the projects."
          materials="Materials Used: I used material-ui frameworks to use icons in the project. I have used arrays and objects to store the information and push it to the database. I used firebase for user authentication and to store the information of users and the projects. I also used npm libraries like particle js and react router dom to enhance the project."
          git="Github: https://github.com/Ujjwal666/CS-ClassWebsite"
          image1={csClass}
          image2={csClass1}
        />
        <Card 
          projectName="Reminder Alarm"
          description="A GUI application for helping me to remind the things i needed to do."
          language="Python"
          github="false"
          objectives="Objectives: This is a GUI application which have the input field for time and message. It also have a json file of youtube links. I used datetime library to get track of time from the user. It converts the inputted text message to speak on the user inputted time. If there is no message inputted it will randomly play a song from the json file."
          materials="Materials Used: I used different libraries like datetime, winsound, gTTS, webbrowser libraries. I used random library to pick the random song from json file."
          git="Github: https://github.com/Ujjwal666/Reminder-Alarm"
        />
        <Card 
          projectName="Restaurant Management"
          description="A simple GUI Restaurant Management system that shows the receipt of the order."
          language="Python"
          github="true"
          objectives="Objectives: This is the GUI application made using Tkinter library. I created this for my class project. The application has the input field where users can search options and it will be added to a dictionary and will be shown along with the price of the entered food and the total sum. It also have the menu page to show the available foods."
          materials=" Materials Used: I used different libraries like Tkinter, time, copy, Image libraries. I used dictionary to check if the user input value is present in the dictionary. "
          git="Github: https://github.com/Ujjwal666/Restro-Management-Tkinter"
          image1={restro}
          image2={restro1}
        />
        <Card
          projectName="Painting Website"
          description="A multi page web app for displaying the paintings I made built with ReactJS and Firebase."
          language="ReactJS  HTML  CSS  Firebase"
          github="false"
          objectives="Objectives: The website has the different pages like home page, about us page, view page. It has the functionality to view more about the paintings where you could see the description of the painting and add to the favorites list or cart list. It also had the user authentication and login features."
          materials="Materials Used: I used material-ui frameworks to use icons in the project. I have used arrays and objects to store the information and push it to the database. I used firebase for user authentication and to store the information of users and the projects. I also used different npm libraries and react router dom."
          git="Github: https://github.com/Ujjwal666/Painting-Ecommerce-Website"
        />
        <Card
          projectName="Weather Forecasting"
          description="A single page web app for looking the weather, sunrise and sunset time by using API."
          language="ReactJS  HTML  CSS"
          github="true"
          objectives="Objectives: The website has the search bar to get the user input where users can enter the place for which they wanted to know the temperature, sunrise time and sunset time of. I uesd the weather API's to get those information about the location "
          materials="Materials Used: I used lists and ternary operators along with API's and react hooks."
          image1={weather}
          image2={weather1}
          git="Github: https://github.com/Ujjwal666/Weather-Forecast"
        />  
      </div>  
    </div>
  );
}

export default Projects;
