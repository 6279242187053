import './Contact.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import emailjs from '@emailjs/browser';
import React, {useRef,useState} from 'react';
function Contact() {

  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3ws6ihh', 'template_o1bpofp', e.target, 'wg0S9FThfAgie5y6c')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
    showResult(true);
  };

  setTimeout(()=>{
    showResult(false);
  }, 5000);

  return (
    <div className="contact">
      <form action=""  onSubmit={sendEmail}>
        <div className="form">
          <p>Get In Touch</p>
          <input type="text" placeholder="Name" name="fullName" required />
          <input type="text" placeholder="Email" name="email" required />
          <textarea type="text" placeholder="Message" name="message" required />
          <button>Submit</button>
          <div className="row">
            {result ? <p>Your message was sent successfully.</p> : null}
          </div>
        </div>
      </form>
      <div className="info">
        <div className="together">
          <LocationOnIcon />
          <p>2251 Sherman Ave NW, Washington, DC 20001</p>
        </div>
        <div className="together">
          <PhoneIcon />
          <p>+1 (469) 662 - 2683</p>
        </div>
        <div className="together">
          <EmailIcon />
          <p>bindasujjwal@gmail.com</p>
        </div>
        <div className="together">
          <PhoneIcon />
          <p>+1 (469) 662 - 2683</p>
        </div>
        <div className="together ic">
          <GitHubIcon />
          <LinkedInIcon style={{marginLeft:"15px"}}/>
        </div>
        <p style={{color:"gray"}}>© All of the Rights Reserved</p>
      </div>
    </div>
  );
}

export default Contact;
