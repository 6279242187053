import React, { useState } from 'react';
import './Card.css';
// create from megan where you will have read more and like that part, create a timeline too.
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";

function Card({github,projectName,description,language,objectives,materials,image1,image2,git}) {

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div className="card" onClick={onOpenModal}>
        <div className="top">
          <FolderOutlinedIcon style={{fontSize:"40px",color:"#1e1e1e"}}/>
          <div className="top_right">
            {github && <GitHubIcon style={{marginRight:"5px",color:"#1e1e1e"}}/>}
            <OpenInNewOutlinedIcon style={{color:"#1e1e1e"}}/>

          </div>
        </div>
        <h1>{projectName}</h1>
        <p style={{marginBottom:"30px"}}>{description}</p>
        <div className="lang">
          <p>{language}</p>
        </div>
      </div>
      <Modal style={{color:"black"}}open={open} onClose={onCloseModal} center>
        <div className="modal">
          <h2 >{projectName}</h2>
          <p>{objectives}</p>
          <p>{materials}</p>
          {github=="true" && 
            <div className="modal_image">
              <p>Images: </p>
              <img src={image1} />
              <img src={image2} />
            </div>  
          }
          
          <p>{git}</p>
        </div>
      </Modal>
    </>
  );
}

export default Card;
