import {useState} from 'react';
import './Footer.css';
import {useStateValue} from "../StateProvider"

function Footer() {

  const [message, setMessage] = useState('');
  const [{message_hook},dispatch] = useStateValue()

  const handleEnter = e =>{
    if (e.keyCode == 13){
      setMessage("")
      dispatch({
        type: 'SET_MESSAGE',
        message_hook: message
      })
    }
  }
  return (
    <div className="footer">
      <p>Ujjwal's-Portfolio:~ portfolio $ </p>
      <input type="text" value = {message} placeholder="█" onChange={e => setMessage((e.target.value).toLowerCase())} onKeyDown={handleEnter}/> 

    </div>
  );
}

export default Footer;
