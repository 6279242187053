import React, { useState } from 'react';
import './Other.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import fowa from './fowa.png'
import capitalone from './capitalone.png'
import howard from './howard.jpeg'
import karkhana from './karkhana.png'
import ncc from './ncc.png'
import google from './google.png'
import courseImage from './courseImage.jpeg'
import { Modal } from 'react-responsive-modal';

function Other() {

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div className="other">
      <Modal style={{color:"black"}}open={open} onClose={onCloseModal} center>
        <div className="modal">
          <h2 >Major-related Courses</h2>
          <p>◘ Product Management</p>
          <p>◘ Intro to Machine Learning </p>
          <p>◘ Software Development Studio</p>
          <p>◘ iOS Mobile Application Development </p>
          <p>◘ CSCI 471 - Applied Data Structures</p>
          <p>◘  Large Scale Programming</p>
          <p>◘ Theory of Computation</p>
          <p>◘ Operating Systems</p>
          <p>◘ Fundamentals of Algorithms</p>
          <p>◘ Cloud Computing</p>
          <p>◘ CSCI 136 - Computer Science II (Python)</p>
          <p>◘ CSCI 481 - Product Management </p>
          <p>◘ CSCI 172 - Careers in Tech</p>
          <p>◘ CSCI 202 - Computer Organization II</p>
          <p>◘ MATH 157 - Calculus II</p>
          <p>◘ CSCI 135 - Computer Science I (Python)</p>
          <p>◘ CSCI 201 - Computer Organization I </p>
          <p>◘ CSCI 100 - Intro to Computer Science (Python)</p>
          <p>◘ EGPP 101 - Intro-Engineering</p>
          <p>◘ CSCI 211 - Unix Lab</p>
          <p>◘ MATH 156 - Calculus I</p>
        </div>
      </Modal>
      <div className="course">
        <div className="course_img">
          <img src={courseImage} />
        </div>
        <div className="course_info">
          <h2>Major-related Courses Howard University</h2>
          <p> Click here to see all of the computer science related courses. My expected graduation date is May 2024.</p>
          <button onClick={onOpenModal} >Learn More</button>
        </div>
      </div>
      <div className="timeline">
        <VerticalTimeline
          lineColor={"#838383"}
        >

          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2023/05 - 2023/08"
            iconStyle={{ background: '#195c5e', color: '#838383' }}

            icon={<img className="image1 image2" src={google} />}
          
          >
            <h3 className="vertical-timeline-element-title">Google's SWE Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
            <p>
              Transformed Google Maps Saved Places into a collaborative product through a comprehensive design document, streamlined UI, and Objective C implementation following coding best practices.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2023/04 - 2023/05"
            iconStyle={{ background: '#195c5e', color: '#838383' }}

            icon={<img className="image1 image2" src={capitalone} />}
          
          >
            <h3 className="vertical-timeline-element-title">Research Assistant</h3>
            <h4 className="vertical-timeline-element-subtitle">Virtual</h4>
            <p>
              Led a student team in anomaly detection of radio frequency signals, preprocessing the data, and achieving 85% training accuracy and 80% testing accuracy using Kernel SVM and Sequential neural network models.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2022/05 - 2022/08"
            iconStyle={{ background: '#195c5e', color: '#838383' }}

            icon={<img className="image1 image2" src={google} />}
          
          >
            <h3 className="vertical-timeline-element-title">Google's STEP Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">Sunnyvale, CA</h4>
            <p>
              Collaboratively co-authored design document for Gmail iOS migration to PhotoKit, mitigated risks of app rejection, explored alternative solutions in Objective C, and facilitated feature launch to Google employees.
            </p>
          </VerticalTimelineElement>
          
          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2022/01 - 2023/02"
            iconStyle={{ background: '#195c5e', color: '#838383' }}

            icon={<img className="image1 image2" src={fowa} />}
          
          >
            <h3 className="vertical-timeline-element-title">HP FOWA Campus Ambassador</h3>
            <h4 className="vertical-timeline-element-subtitle">Washington, DC</h4>
            <p>
              I helped students to engage to participate in the Future of Work Academy and identified faculty/staff who might be interested in helping support the student engagement.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2022/01-2022/04"
            iconStyle={{ background: '#195c5e', color: '#838383' }}
            icon={<img className="image1 image2" src={capitalone} />}
            
          >
            <h3 className="vertical-timeline-element-title">VIP Project Capital One</h3>
            <h4 className="vertical-timeline-element-subtitle">Washington, DC</h4>
            <p>
              I worked with my fellow team members to do analysis of large and/or complex data sets using machine and deep learning tools.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2021/08 - 2022/01"
            iconStyle={{ background: '#195c5e', color: '#838383' }}
            icon={<img className="image1 image2" src={howard} />}
            
          >
            <h3 className="vertical-timeline-element-title">Resident Assistant</h3>
            <h4 className="vertical-timeline-element-subtitle">Washington, DC</h4>
            <p>
              I helped solve the problems faced by residents and organize events for them so they get coonnected. I also made sure the residents were following university's protocols.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement

            className="vertical-timeline-element--work"
            contentStyle={{ background: '#195c5e', color: '#a3a644' }}
            contentArrowStyle={{ borderRight: '7px solid  #195c5e' }}
            date="2015/08 - 2016/01"
            iconStyle={{ background: '#195c5e', color: '#838383' }}
            icon={<img style={{marginLeft:"3px"}}className="image1 image2" src={ncc} />}
          >
            <h3 className="vertical-timeline-element-title">National Cadet Corps Training</h3>
            <h4 className="vertical-timeline-element-subtitle">Chitwan, Nepal</h4>
            <p>
              As, I used to read in a military school, I got an opportunity have a 72 days trainig where we were given training related leadership, handling situation, communication skills.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Other;
