export const initialState = {
  message_hook: null,
}

const reducer = (state,action) => {
  switch(action.type){
    case 'SET_MESSAGE':
      return{
        ...state,
        message_hook: action.message_hook,
      }
    default:
      return state;
  }
}

export default reducer;