import './Skills.css';
import ProgressBar from 'react-animated-progress-bar'
// create like erics website have circle for technical skills and describe leave blank for professional
function Skills() {

  return (
    <div className="skills">
      <div className="prof_skill">
        <p>Technical Skills</p>
        <div className="professional">
          <div className="inner">
            <ProgressBar style={{textAlign:"center"}} width="230" trackWidth="13" percentage="80" fontColor="gray"/>
            <p>Python</p>
            <div className="back">
              <p >In the past 3 years, I have acquired proficiency in Python through my university's curriculum and research activities. Additionally, I have successfully completed various projects utilizing Python for diverse applications.</p>
            </div>
          </div>
          <div className="inner">
            <ProgressBar style={{textAlign:"center"}} width="230" trackWidth="13" percentage="60" fontColor="gray"/>
            <p>Objective C</p>
            <div className="back">
              <p > Over the past 2 years, I have gained comprehensive experience in Objective C through both online courseworks and internship engagements. During this period, I have successfully contributed to various projects, utilizing Objective C to develop innovative and practical solutions.</p>
            </div>
          </div>
          <div className="inner">
            <ProgressBar width="230" trackWidth="13" percentage="60" fontColor="gray"/>
            <p>ReactJS, HTML, CSS</p>
            <div className="back">
              <p >I have acquired substantial expertise in HTML/CSS since my high school days, and I frequently engage in front-end web application development.</p>
            </div>
          </div>
          <div className="inner">
            <ProgressBar width="230" trackWidth="13" percentage="60" fontColor="gray"/>
            <p>Swift</p>
            <div className="back">
              <p >
I have gained significant expertise in Swift through my CodePath class, where I honed my skills in iOS app development using this programming language.</p>
            </div>
          </div>
          <div className="inner">
            <ProgressBar width="230" trackWidth="13" percentage="30" fontColor="gray"/>
            <p>Java</p>
            <div className="back">
              <p >I have little exposure to Java.I got experience using Java to create a responsive user interactive
              page in the Android Studio in one of my professor's project and during my Large Scale Programming class.</p>
            </div>
          </div>
          <div className="inner">
            <ProgressBar width="230" trackWidth="13" percentage="20" fontColor="gray"/>
            <p>Firebase</p>
            <div className="back">
              <p >I have used Firebase over the past years to develop a web applications to store the data and for 
              the user authentication. I have integrated firebase in my projects like CS-Class Website, and Painting Website.</p>
            </div>
          </div>
         <div className="inner">
          <ProgressBar width="230" trackWidth="13" percentage="5" fontColor="gray"/>
          <p>MongoDB</p>
          <div className="back">
            <p>I have experience using MongoDB during my Software Development Studio course. </p>
          </div>
          </div>
        </div>
      </div> 
      <div className="tech_skill">
        <p>Professional Skills</p>
        <div className="tech_div">
          <div className="tech_left">
            <div className="tech">
              <p>Communication</p>
              <ProgressBar 
                className="tech_bar"
                height="10px"
                rect
                fontColor="gray"
                percentage="80"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="grey"
              />
              
            </div>
            <div className="tech">
              <p>Time Management</p>
              <ProgressBar 
                height="10px"
                rect
                fontColor="gray"
                percentage="90"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="grey"
              />
            </div>
            <div className="tech">
              <p>Leadership</p>
              <ProgressBar 
                height="10px"
                rect
                fontColor="gray"
                percentage="70"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="grey"
              />
             </div> 
            <div className="tech">
              <p>Collaboration</p>
              <ProgressBar 
                height="10px"
                rect
                fontColor="gray"
                percentage="80"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="grey"
              />
            </div>
            <div className="tech">
              <p>Adaptability</p>
              <ProgressBar 
                height="10px"
                rect
                fontColor="gray"
                percentage="80"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="grey"
              />
            </div>
          </div>
        </div>
      </div>       
    </div>
  );
}

export default Skills;
