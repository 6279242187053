import './Introduction.css';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from './logo.jpeg'
function Introduction() {


  return (
    <div className="introduc">
      <div className="introduction">

        <div className="intro">
          <h1>Hello! 👋 I'm Ujjwal Adhikari, a senior-year Computer Science student at Howard University.</h1>
          <p>Talking about my background, I have had the opportunity to explore various areas of computer science, from programming and 
          mobile application development to web application development and database management. Throughout my academic career,
           I have honed my skills in various programming languages and tools, including Objective C, Python, Swift, ReactJS, HTML/CSS, Java, C++, Kotlin,
           and MongoDB. My motoo is to develop applications that solving real-world problems and make positive impact on users.</p> 
        </div>
        <div className="image">
          <img src={logo} />
        </div>
      </div>

      <div className="icons">
        <a href="https://github.com/Ujjwal666">
          <div className="same">
            <GitHubIcon style={{color:'#27973d',fontSize:'30px'}}/>
            <p style={{background:'#146e6f'}}> Github </p>
          </div>
        </a>
        <a href="https://www.linkedin.com/in/ujjwal-adhikari-3a946418b/">
          <div className="same">
            <LinkedInIcon style={{color:'#27973d',fontSize:'30px'}}/>
            <p style={{background:'#a6a944'}}>LinkedIn </p>
          </div>
        </a>
      </div>

    </div>
  );
}

export default Introduction;
