import './Body.css';
import {useStateValue} from "../StateProvider"
import Introduction from '../Introduction/Introduction';
import Projects from '../Projects/Projects';
import Skills from '../Skills/Skills';
import Contact from '../Contact/Contact';
import Other from '../Other/Other';

function Body() {

  const [{message_hook},dispatch] = useStateValue()

  return (
    <div className="body">
      {message_hook == 'cd projects' ? <Projects /> : message_hook == 'cd skills' ? <Skills />: message_hook == 'cd contact' ? <Contact /> : message_hook == 'cd other' ? <Other /> : <Introduction />}

    </div>
  );
}

export default Body;
