import './App.css';
import Home from './Home/Home';
import Footer from './Footer/Footer';
import Body from './Body/Body';
import CircleIcon from '@mui/icons-material/Circle';
import FolderIcon from '@mui/icons-material/Folder';

function App() {
  return (
    <div className="app">
      <div className="main">
        <div className="header">
          <CircleIcon style={{color:'#ff554e',paddingRight:'2px',paddingLeft:'2px'}} className="circle_icon"/>
          <CircleIcon style={{color:'#ffbd2f',paddingRight:'2px'}} className="circle_icon" />
          <CircleIcon style={{color:'#29cb40', paddingRight:'2px'}} className="circle_icon"/>
          <FolderIcon style={{color:'#68b7d9'}} className="folder_icon"/>
          <p>portfolio -- ujjwal_adhikari</p>
        </div>
        <div className="header_two">
          <p>Compiled successfully!</p>
          <p style={{marginTop:"-10px",fontSize:"13px"}}>Inside this terminal you can run below cd commands to move through different parts of my portfolio.</p>
          <div className="commands">
            <p style={{color:"#146e6f"}}>cd projects</p>
            <p style={{color:"#c6c6c6",marginLeft:"5px"}}>- Takes you to the projects section</p>
          </div>
          <div className="commands">
            <p style={{color:"#146e6f"}}>cd skills</p>
            <p style={{color:"#c6c6c6",marginLeft:"5px"}}>- Takes you to the skills section</p>
          </div>
          <div className="commands">
            <p style={{color:"#146e6f"}}>cd contact</p>
            <p style={{color:"#c6c6c6",marginLeft:"5px"}}>- Takes you to the contact section</p>
          </div>
          <div className="commands">
            <p style={{color:"#146e6f"}}>cd other</p>
            <p style={{color:"#c6c6c6",marginLeft:"5px"}}>- Takes you to the other section</p>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
        <div className="body">
          <Body />
        </div>
      </div>
    </div>
  );
}

export default App;
